.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.no-margin{
  margin-bottom: 0;
}

.FooterLink{
  color: #fff;
  text-decoration: none;
}
.FooterLink:hover{
  color: #C72229;
}

.nav-link:hover {
  background-color: #C72229;
}

.dialog {
  box-shadow: 0 8px 6px -6px black;
  border-radius: 20px;
} 

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.white-container{
  background-color: white;
  border-radius: 12px;
}

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

#lightbox {
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  /*optional bg color*/
  background-color: rgba(0, 0, 0, 0.678);
}
